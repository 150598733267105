<template>
  <div class="forms">
    <Card style="margin-left: 199px">
      <div style="height: ">
        <div class="titles">添加店铺入驻资料</div>
        <div class="content">
          <div class="con_bor">
            <div class="jan_tex"></div>
            <div class="addcontent">
              <Form
                ref="formValidate"
                :model="formValidate"
                :rules="ruleValidate"
                :label-width="80"
              >
                <FormItem label="账号" prop="name">
                  <Input
                    v-model="formValidate.name"
                    placeholder="请输入账号"
                  ></Input>
                </FormItem>

                <FormItem label="密码" prop="passwd">
                  <Input
                    type="password"
                    v-model="formValidate.passwd"
                    placeholder="请输入密码"
                  ></Input>
                </FormItem>

                <FormItem label="确认密码" prop="passwdCheck">
                  <Input
                    type="password"
                    v-model="formValidate.passwdCheck"
                    placeholder="请再次输入密码"
                  ></Input>
                </FormItem>

                <FormItem label="店铺名称" prop="shop_name">
                  <Input
                    v-model="formValidate.shop_name"
                    placeholder="请输入店铺名称"
                  ></Input>
                </FormItem>

                <FormItem label="商铺品牌" prop="brand">
                  <Select v-model="formValidate.brand" placeholder="请选择">
                    <Option
                      :value="item.b_id"
                      v-for="(item, index) in datas.brand"
                      :key="index"
                      >{{ item.b_name }}</Option
                    >
                  </Select>
                </FormItem>

                <el-upload
                  class="avatar-uploader"
                  :action="uploadingUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="imageUrl"
                    :src="imageUrl"
                    class="avatar"
                    alt="图片走丢了"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <div slot="tip" class="el-upload__tip">
                    只能上传jpg/png文件，且不超过500kb，建议图片大小350*200px
                  </div>
                </el-upload>

                <FormItem label="所属行业" prop="industry">
                  <Select v-model="formValidate.industry" placeholder="请选择">
                    <Option
                      :value="item.i_id"
                      v-for="(item, index) in datas.industry"
                      :key="index"
                      >{{ item.i_name }}</Option
                    >
                  </Select>
                </FormItem>

                <FormItem
                  label="所属支行"
                  prop="industry"
                  v-show="bankListShow"
                >
                  <Select
                    placeholder="请选择"
                    @on-change="selectBank"
                    :label-in-value="true"
                    v-model="formValidate.bank_id"
                  >
                    <Option
                      :value="item.id"
                      v-for="(item, index) in bankList"
                      :key="index"
                      >{{ item.s_name }}</Option
                    >
                  </Select>

                  <!-- <el-select
                    style="width: 45%; margin-left: 10%"
                    v-model="formValidate.bank_id"
                    placeholder="所属支行"
                  >
                    <el-option
                      v-for="item in bankList"
                      :key="item.id"
                      :label="item.s_name"
                      :value="item.id"
                      @change="selectBank(e)"
                    >
                    </el-option>
                  </el-select> -->
                </FormItem>

                <FormItem label="主营类目" prop="category">
                  <Select v-model="formValidate.category" placeholder="请选择">
                    <Option
                      :value="item.c_id"
                      v-for="(item, index) in datas.category"
                      :key="index"
                      >{{ item.c_name }}</Option
                    >
                    <!-- <Option value="shanghai">London</Option> -->
                    <!-- <Option value="shenzhen">Sydney</Option> -->
                  </Select>
                </FormItem>

                <FormItem label="商家详细地址" prop="site">
                  <Input
                    v-model="formValidate.site"
                    placeholder="请输入地址"
                  ></Input>
                </FormItem>

                <FormItem label="联系人" prop="linkman">
                  <Input
                    v-model="formValidate.linkman"
                    placeholder="请输入联系人"
                  ></Input>
                </FormItem>

                <FormItem label="联系电话" prop="phone">
                  <Input
                    v-model="formValidate.phone"
                    placeholder="请输入手机号"
                  ></Input>
                </FormItem>

                <FormItem label="入驻时间">
                  <Row>
                    <Col span="11">
                      <FormItem prop="date">
                        <DatePicker
                          type="date"
                          placeholder="日期"
                          v-model="formValidate.date"
                        ></DatePicker>
                      </FormItem>
                    </Col>
                  </Row>
                </FormItem>

                <FormItem label="过期时间">
                  <Row>
                    <Col span="11">
                      <FormItem prop="date2">
                        <DatePicker
                          type="date"
                          placeholder="日期"
                          v-model="formValidate.date2"
                        ></DatePicker>
                      </FormItem>
                    </Col>
                  </Row>
                </FormItem>

                <FormItem label="商家介绍" prop="introduce">
                  <Input
                    v-model="formValidate.introduce"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="请输入商家介绍"
                  ></Input>
                </FormItem>

                <FormItem label="到店自取" prop="invite">
                  <RadioGroup v-model="formValidate.invite">
                    <Radio label="支持"></Radio>
                    <Radio label="不支持"></Radio>
                  </RadioGroup>
                </FormItem>

                <FormItem label="是否审核" prop="audit">
                  <RadioGroup v-model="formValidate.audit">
                    <Radio label="审核"></Radio>
                    <Radio label="不审核"></Radio>
                  </RadioGroup>
                </FormItem>

                <FormItem>
                  <Button type="error" @click="handleSubmit('formValidate')">{{
                    addChange
                  }}</Button>
                  <Button
                    @click="handleReset('formValidate')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  name: "forms",
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密吗"));
      } else {
        if (this.formValidate.passwdCheck !== "") {
          // 对第二个密码框单独验证
          this.$refs.formValidate.validateField("passwdCheck");
        }
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密吗"));
      } else if (value !== this.formValidate.passwd) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };
    return {
      addChange: "添加",
      uploadingUrl: "",
      imageUrl: "",
      bankListShow: false,
      bankList: [],

      formValidate: {
        bank_id: "", //支行id
        bank_name: "", //支行名称
        animal: "爪哇犀牛",
        name: "", //名字
        mail: "",
        city: "",
        gender: "",
        interest: [],
        date: "",
        time: "",
        date2: "",
        time2: "",
        desc: "",
        pass: "",
        shop_name: "", //商铺
        passwd: "", //密码
        passwdCheck: "", //再次密码
        site: "", //地址
        brand: "", //品牌
        category: "", //类目
        phone: "", //手机号
        linkman: "", //联系人
        enter: "", //入驻电话
        introduce: "", //介绍
        // introduce: ''
        invite: "不支持", //到店自取
        audit: "不审核", //审核
        industry: "",
      },
      ruleValidate: {
        passwd: [
          {
            required: true,
            message: "请输入密码",
            validator: validatePass, //密码
            trigger: "blur",
          },
        ],
        passwdCheck: [
          {
            required: true,
            message: "请输入密码",
            validator: validatePassCheck, //再次输入密码
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入账号", //账号
            trigger: "blur",
          },
        ],
        shop_name: [
          {
            required: true,
            message: "请输入店铺名称", //店铺
            trigger: "blur",
          },
        ],
        site: [
          {
            required: true,
            message: "请输入地址", //地址
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号", //手机号
            trigger: "blur",
          },
        ],
        linkman: [
          {
            required: true,
            message: "请输入联系人", //手机号
            trigger: "blur",
          },
        ],
        enter: [
          {
            required: true,
            message: "请输入入驻电话", //入驻电话
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: "Please select the city", //品牌
            trigger: "change",
          },
        ],
        introduce: [
          {
            required: true,
            message: "请输入介绍",
            trigger: "change",
          },
        ],
        gender: [
          {
            required: true,
            message: "Please select gender",
            trigger: "change",
          },
        ],
        interest: [
          {
            required: true,
            message: "Please select gender",
            trigger: "change",
          },
        ],
        audit: [
          {
            required: true,
            message: "Please select gender",
            trigger: "change",
          },
        ],
        invite: [
          {
            required: true,
            message: "Please select gender",
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            type: "date",
            message: "Please select the date",
            trigger: "change",
          },
        ],
        date2: [
          {
            required: true,
            type: "date",
            message: "Please select the date",
            trigger: "change",
          },
        ],
        desc: [
          {
            required: true,
            message: "Please enter a personal introduction",
            trigger: "blur",
          },
          {
            type: "string",
            min: 20,
            message: "Introduce no less than 20 words",
            trigger: "blur",
          },
        ],
      },
      datas: null,
      logo: null,
      isshow: true,
    };
  },
  created() {
    this.uploadingUrl = this.$request.imgUrl() + "PHPExcel/fileUpload";
    // console.log(this.$request.imgUrl());
    let token = this.$storage.getLocal("token");

    this.admin_type = this.$storage.getLocal("type");
    if (this.admin_type == 2) {
      this.bankListShow = true;
    }

    this.$request.subbranchSelData({ token }).then((res) => {
      if (res.code == 0) {
        console.log(res.data);
        console.log(this.bankList);
        // return
        this.bankList = res.data;
        console.log(this.bankList);
      }
    });
    
    this.$request
      .storeSelectData({
        token,
      })
      .then((res) => {
        if (res.code == 0) {
          console.log(res);
          this.datas = res.data;
        }
      });
    if (this.$request.getUrl("id")) {
      // let apis = this.$request.imgUrl()
      this.addChange = "修改";
      console.log("编辑");
      this.isshow = false;
      this.$request
        .storeOneData({ token, id: this.$request.getUrl("id") })
        .then((res) => {
          this.formValidate.name = res.data.login_name; //名字
          this.formValidate.linkman = res.data.user; //联系人
          this.formValidate.phone = res.data.tel; //手机号
          this.formValidate.passwd = res.data.login_pwd; //密码
          this.formValidate.shop_name = res.data.s_name; //商铺
          this.formValidate.site = res.data.s_address; //地址
          this.formValidate.brand = res.data.b_id; //品牌
          this.formValidate.introduce = res.data.s_content; //介绍
          this.formValidate.category = res.data.c_id; //类目
          this.formValidate.industry = res.data.i_id; //行业
          this.formValidate.invite = res.data.is_take == 1 ? "支持" : "不支持"; //到店自取
          this.formValidate.audit = res.data.is_judge == 1 ? "审核" : "不审核"; //审核
          this.logo = res.data.s_logo;
          this.formValidate.date = res.data.start_time; //开始时间
          this.formValidate.date2 = res.data.end_time; //结束时间
          this.formValidate.bank_name = res.data.su_name; 
          this.formValidate.bank_id = res.data.su_id; 
          // this.imageUrl = 'http://192.168.0.106/uploads/' + res.data.s_logo //本地
          this.imageUrl =
            this.$request.readimgUrl() + "/uploads/" + res.data.s_logo; //读取路径
          // this.imageUrl = this.$request.imgUrl() + res.data.s_logo; //线上
        });
    }
  },
  methods: {
    //选中支行
    selectBank(e) {
      console.log(e);
      this.formValidate.bank_id = e.value;
      this.formValidate.bank_name = e.label;
    },
    handleSubmit(name) {
      let token = this.$storage.getLocal("token");
      // console.log(this.formValidate.date);
      // console.log(this.formValidate.date2);
      // console.log(this.formValidate)
      // var brad=''
      // if(this.formValidate.brand !==undefined &&this.formValidate.brand != null ||  this.formValidate.brand.length > 0 )
      // {
      //  var brad=this.formValidate.brand
      // }
      // console.log(111,brad)
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.isshow) {
            let arr = {
              token,
              name: this.formValidate.name, //名字
              shop_name: this.formValidate.shop_name, //商铺
              passwd: this.formValidate.passwd, //密码
              passwdCheck: this.formValidate.passwdCheck, //再次密码
              site: this.formValidate.site, //地址
              brand: this.formValidate.brand, //品牌
              category: this.formValidate.category, //类目
              phone: this.formValidate.phone, //手机号
              linkman: this.formValidate.linkman, //联系人
              enter: this.formValidate.enter, //入驻电话
              industry: this.formValidate.industry, //行业
              introduce: this.formValidate.introduce, //介绍
              invite: this.formValidate.invite == "不致辞" ? 2 : 1, //到店自取
              audit: this.formValidate.audit == "不审核" ? 2 : 1, //审核
              end_date: this.formValidate.date2, //结束时间
              start_date: this.formValidate.date, //开始时间
              s_logo: this.logo,
              su_name: this.formValidate.bank_name,
              su_id: this.formValidate.bank_id,
            };
            this.$request.addStoreData(arr).then((res) => {
              // return false
              if (res.code == 0) {
                this.handleReset("formValidate");
                this.$Message.success("添加成功");
                this.$router.push({ path: "/addList" });
              } else if (res.code == 1) {
                this.$Message.error(res.msg);
              }
            });
          } else {
            let arr1 = {
              token,
              name: this.formValidate.name, //名字
              shop_name: this.formValidate.shop_name, //商铺
              passwd: this.formValidate.passwd, //密码
              passwdCheck: this.formValidate.passwdCheck, //再次密码
              site: this.formValidate.site, //地址
              brand: this.formValidate.brand, //品牌
              category: this.formValidate.category, //类目
              phone: this.formValidate.phone, //手机号
              linkman: this.formValidate.linkman, //联系人
              enter: this.formValidate.enter, //入驻电话
              industry: this.formValidate.industry, //行业
              introduce: this.formValidate.introduce, //介绍
              invite: this.formValidate.invite == "不致辞" ? 2 : 1, //到店自取
              audit: this.formValidate.audit == "不审核" ? 2 : 1, //审核
              end_date: this.formValidate.date2, //结束时间
              start_date: this.formValidate.date, //开始时间
              s_logo: this.logo,
              id: this.$request.getUrl("id"),
              su_name: this.formValidate.bank_name,
              su_id: this.formValidate.bank_id,
            };
            // console.log(arr1);
            this.$request.upStoreData(arr1).then((res) => {
              // console.log(res)
              const h = this.$createElement;
              this.$notify({
                title: "消息提示",
                message: h(
                  "i",
                  { style: "color: red;font-size:28px" },
                  "修改成功"
                ),
              });
            });
            this.$router.push({ path: "/addList" });
          }
        } else {
          this.$Message.error("请补齐资料");
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.imageUrl = "";
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.logo = res.data.src;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style scoped="scoped">
.con_bor {
  width: 95%;
  margin: 0 auto;
}

.addcontent {
  width: 40%;
  margin: 0 auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader-icon {
  border: 1px solid #333333;
}
</style>
