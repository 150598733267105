<template>
	<div class="addShop">
		<frame :top1="top1" :top2="top2">
			<forms></forms>
		</frame>
	</div>
</template>

<script>
	import frame from '../public/Frame.vue';
	import forms from '../template/Form.vue';
	export default {
		name: 'member',
		data() {
			return {
				top1: '2-5',
				top2: ['2']
			}
		},
		created() {},
		components: {
			frame,
			forms
		},
		methods: {

		}
	}
</script>


<style scoped="scoped">

</style>
